<template>
    <v-container>
        <AConfirmation :isShow="isPendingRefundStatus" @confirm="updateRefundStatus" @cancel="cancelUpdateRefundStatus" />
        <ASuccessWithoutReload :api="this.api" @close="() => { api.isSuccesful = false; }" />
        <AError :api="this.api" />
        <v-skeleton-loader type="card" v-if="this.isLoadingRefund">
        </v-skeleton-loader>
        <div v-if="!this.isLoadingRefund">
            <div class="text-h6 my-4 font-weight-bold">
                Refund Status
            </div>
            <div v-if="refund != null && !isRefund">
                <v-carousel height="auto" width="auto" :continuous="false" hide-delimiters>
                    <v-carousel-item v-for="(item, index) in this.refund" :key="index">
                        <div class="d-flex justify-space-between">
                            <p class="">
                                Issue By :
                            </p>
                            <p class="">
                                {{ item.user_name }}
                            </p>
                        </div>
                        <div class="d-flex justify-space-between">
                            <p class="">
                                Issue Date :
                            </p>
                            <p class="">
                                {{ convertTimeZone(item.created_at) }}
                            </p>
                        </div>
                        <div class="d-flex justify-space-between">
                            <p class="text-no-wrap">
                                Refund Through :
                            </p>
                            <p class="text-right">
                                {{ item.refund_type }}
                            </p>
                        </div>
                        <div class="d-flex justify-space-between" v-if="item.refund_type == 'Bank Transfer'">
                            <p class="text-no-wrap">
                                Bank :
                            </p>
                            <p class="text-right">
                                {{ item.bank_type }}
                            </p>
                        </div>
                        <div class="d-flex justify-space-between" v-if="item.refund_type == 'Bank Transfer'">
                            <p class="text-no-wrap">
                                Account No. :
                            </p>
                            <p class="text-right">
                                {{ item.bank_acc_no }}
                            </p>
                        </div>
                        <div class="d-flex justify-space-between" v-if="item.refund_type == 'Bank Transfer'">
                            <p class="text-no-wrap">
                                Account Holder :
                            </p>
                            <p class="text-right">
                                {{ item.bank_holder_name }}
                            </p>
                        </div>
                        <div v-if="isLoadingRefund == true">
                            <v-progress-circular v-if="isLoadingRefund" :size="50" color="primary"
                                indeterminate></v-progress-circular>
                        </div>
                        <div v-else>
                            <div v-if="allowedRoleUpdateStatus.some(role => $store.getters.getRole.includes(role))">
                                <p>
                                    Status :
                                </p>
                                <v-autocomplete @change="validateInputChangeRefundStatus(item.refund_id, item.status)"
                                    :items="refundOption" outlined dense v-model="item.status">
                                </v-autocomplete>
                            </div>
                            <div v-else class="d-flex justify-space-between">
                                <p>
                                    Status
                                </p>
                                <div class="mt-n1 mb-7">
                                    <v-chip class="white--text" style="border-radius: 13px;" color="orange"
                                        v-if="item.status == 'Pending'">
                                        {{ item.status }}
                                    </v-chip>
                                    <v-chip class="white--text" style="border-radius: 13px;" color="green"
                                        v-if="item.status == 'Done'">
                                        {{ item.status }}
                                    </v-chip>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-space-between mt-n3 mb-3">
                            <div class="font-weight-bold mt-2">
                                {{ index + 1 }} / {{ refund.length }}
                            </div>
                            <v-btn class="custom-button text-capitalize" outlined>
                                <span class="font-weight-bold">
                                    Show All
                                </span>
                            </v-btn>
                        </div>
                    </v-carousel-item>
                </v-carousel>
            </div>
            <div v-else>
                <span class="d-flex justify-start">
                    No refund recorded
                </span>
            </div>
        </div>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
import ASuccessWithoutReload from '../../common/ASuccessWithoutReload.vue';

export default {
    components: {
        ASuccessWithoutReload,
    },
    computed: mapState({
        //
    }),
    props: [
        //
    ],
    data: () => ({
        notAllowed: false,
        notAllowedAction: false,
        isLoadingRefund: false,
        isPendingRefundStatus: false,
        actionDrop: false,
        role: null,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },
        allowedRoleUpdateStatus: ["developer", "vision_admin", "operation"],
        refundOption: ["Pending", "Done"],
        refund: null,
        isRefund: false,
        tempRefundId: null,
        tempRefundStatus: null,
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class === "getCompanyRefund") {
                this.refund = (Object.values(resp.data).length != 0) ? resp.data : null;
                this.isRefund = false;
                this.isLoadingRefund = false;
            }
            if (resp.class == "updateRefundStatus") {
                this.isLoadingRefund = false;
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Update Refund Status";
            }
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            let companyId = this.$router.history.current.params.id;
            // let fetchSubscriptionApi = this.fetchCompanySubscription(companyId);
            // let fetchRenewalStatusApi = this.fetchRenewalStatus(companyId);
            // let fetchLatestPaymentStatusApi = this.fetchPaymentStatus(companyId);
            // let fetchClientPICApi = this.fetchClientPIC(companyId);
            let fetchRefundApi = this.fetchRefund(companyId);
            // let fetchAttachmentApi = this.fetchAttachment();
            // let fetchSpecialRemarkApi = this.fetchSpecialRemark();
            // let fetchCompanySubscriptionHistoryApi = this.fetchCompanySubscriptionHistory(companyId);
            // let fetchCompanyRefundApi = this.fetchCompanyRefund(companyId);
            this.$axios.all([
                // this.$api.fetch(fetchSubscriptionApi),
                // this.$api.fetch(fetchClientPICApi),
                // this.$api.fetch(fetchRenewalStatusApi),
                // this.$api.fetch(fetchLatestPaymentStatusApi),
                this.$api.fetch(fetchRefundApi),
                // this.$api.fetch(fetchAttachmentApi),
                // this.$api.fetch(fetchSpecialRemarkApi),
                // this.$api.fetch(fetchCompanySubscriptionHistoryApi),
                // this.$api.fetch(fetchCompanyRefundApi),
            ]);
        },
        fetchRefund(companyId) {
            this.isLoadingRefund = true;
            this.isRefund = true;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/refund/company/" + companyId;
            return tempApi;
        },
        validateInputChangeRefundStatus(refundId, refundStatus) {
            this.tempRefundStatus = refundStatus;
            this.tempRefundId = refundId;
            this.isPendingRefundStatus = true;
        },
        updateRefundStatus() {
            this.isPendingRefundStatus = false;
            this.isLoadingRefund = true;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/refund/id/" + this.tempRefundId;
            tempApi.params = {
                'status': this.tempRefundStatus,
            }
            this.$api.fetch(tempApi);
        },
        cancelUpdateRefundStatus() {
            this.isPendingRefundStatus = false;
            location.reload();
        },
        convertTimeZone(time) {
            return this.$moment(time).format('LLL');
        },
        removeNavigationButtons() {
            const prevButton = document.querySelector('.v-slide-group__prev');
            const nextButton = document.querySelector('.v-slide-group__next');

            if (prevButton && prevButton.parentNode) {
                prevButton.parentNode.removeChild(prevButton);
            }

            if (nextButton && nextButton.parentNode) {
                nextButton.parentNode.removeChild(nextButton);
            }
        },
        changeFlexShrink() {
            const slideGroupContent = document.querySelector('.v-slide-group__content');

            if (slideGroupContent) {
                slideGroupContent.style.flex = '1 1 auto';
            }
        },
    },
}
</script>

<style scoped>
.custom-button {
    border: 2px solid black;
    border-radius: 5px;
}
</style>