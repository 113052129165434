<template>
    <v-container>
        <AError :api="this.api" />
        <v-skeleton-loader type="card" v-if="this.isLoadingClient">
        </v-skeleton-loader>
        <v-tabs class="custom-tabs" active-class="custom-active-tab" slider-color="transparent" fixed-tabs
            v-if="!this.isLoadingClient">
            <v-tab class="text-capitalize rounded-l black--text" style="border-width: 2px 1px 2px 2px;">
                client
            </v-tab>
            <v-tab class="text-capitalize black--text rounded-r" style="border-width:2px 2px 2px 1px">
                Renewal PIC
            </v-tab>
            <v-tab-item>
                <div class="text-h6 my-4 font-weight-bold">
                    Client
                </div>
                <v-carousel height="auto" width="auto" :continuous="false" hide-delimiters>
                    <v-carousel-item v-for="(item, index) in client" :key="index">
                        <v-card class="px-5 pt-5 mb-7 rounded-lg" flat color="blue-grey lighten-5">
                            <div class="d-flex justify-space-between">
                                <p class="text-no-wrap">
                                    Name :
                                </p>
                                <p class="text-right">
                                    {{ item.fname }} {{ item.lname }}
                                </p>
                            </div>
                            <div class="d-flex justify-space-between">
                                <p class="text-no-wrap">
                                    Position :
                                </p>
                                <p class="text-right">
                                    {{ (item.position) ? item.position : "-" }}
                                </p>
                            </div>
                            <div class="d-flex justify-space-between" v-if="checkIfCanAccessBonusOffer()">
                                <p class="text-no-wrap">
                                    Account Category :
                                </p>
                                <p class="text-right">
                                    {{ (item.acc_category) ? item.acc_category : "-" }}
                                </p>
                            </div>
                            <div class="d-flex justify-space-between" v-if="checkIfCanAccessBonusOffer()">
                                <p class="text-no-wrap">
                                    Bonus Offer :
                                </p>
                                <p class="text-right">
                                    {{ (item.bonus_offer) ? item.bonus_offer : "-" }}
                                </p>
                            </div>
                            <div class="d-flex justify-space-between">
                                <p class="text-no-wrap">
                                    Phone no :
                                </p>
                                <p class="text-right">
                                    {{ item.mobile }}
                                </p>
                            </div>
                            <div class="d-flex justify-space-between">
                                <p class="text-no-wrap">
                                    Email :
                                </p>
                                <p class="text-right">
                                    {{ item.email }}
                                </p>
                            </div>
                            <div class="d-flex justify-space-between">
                                <p class="text-no-wrap">
                                    Existing Client :
                                </p>
                                <p class="text-right" v-if="item.existing_client != null">
                                    {{ item.existing_client }}
                                </p>
                                <p v-else>
                                    -
                                </p>
                            </div>
                            <div
                                class="d-flex justify-space-between">
                                <p
                                    class="text-no-wrap">
                                    Customer service :
                                </p>
                                <p
                                    class="text-right" v-if="item.cs_id !=null">
                                    {{ (item.cs_name!=null)?item.cs_name:'-'}}
                                </p>
                                <p class="text-right"
                                    v-else>
                                    -
                                </p>        
                            </div>
                            <div
                                class="d-flex justify-space-between">
                                <p
                                    class="text-no-wrap">
                                    Welcome Guide Send Date :
                                </p>
                                <p
                                    class="text-right" v-if="item.welcome_guide_sent!=null">
                                    {{ (item.welcome_guide_sent!=null)?convertDateZone(item.welcome_guide_sent):'-' }}
                                </p>
                                <p
                                    class="text-right"
                                    v-else>
                                    -
                                </p>
                            </div>
                            <div
                                class="d-flex justify-space-between">
                                <p
                                    class="text-no-wrap">
                                    Welcome WA Send Date
                                </p>
                                <p
                                    class="text-right"
                                    v-if="item.welcome_wa_sent!=null">
                                    {{ (item.welcome_wa_sent!=null)?convertDateZone(item.welcome_wa_sent):'-' }}
                                </p>
                                <p
                                    class="text-right"
                                    v-else>

                                </p>
                            </div>
                            <div class="d-flex justify-space-between">
                                <p class="text-no-wrap">
                                    Active :
                                </p>
                                <p class="text-right" v-if="item.active != null">
                                    {{ item.active }}
                                </p>
                                <p v-else>
                                    -
                                </p>
                            </div>
                            <div class="d-flex justify-space-between">
                                <p class="text-no-wrap">
                                    Gender :
                                </p>
                                <p class="text-right">
                                    {{ (item.gender) ? item.gender : "-" }}
                                </p>
                            </div>
                            <div class="d-flex justify-space-between">
                                <p class="text-no-wrap">
                                    Referral Code :
                                </p>
                                <p class="text-right">
                                    {{ item.referral_uuid }}
                                </p>
                            </div>
                        </v-card>
                    </v-carousel-item>
                </v-carousel>
                <!-- <div class="d-flex justify-end mt-n4 mb-3">
                    <v-btn class="custom-button text-capitalize" outlined>
                        <span class="font-weight-bold">
                            Show All
                        </span>
                    </v-btn>
                </div> -->
            </v-tab-item>
            <v-tab-item>
                <div class="text-h6 my-4 font-weight-bold">
                    PIC
                </div>
                <div v-if="(this.staff != null)">
                    <v-carousel height="auto" width="auto" :continuous="false" hide-delimiters>
                        <v-carousel-item v-for="(item, index) in staff" :key="index">
                            <v-card class="px-5 pt-5 mb-7 rounded-lg" flat color="blue-grey lighten-5">
                                <div class="d-flex justify-space-between">
                                    <p class="text-no-wrap">
                                        Name :
                                    </p>
                                    <p class="text-right">
                                        {{ item.fname }} {{ item.lname }}
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-no-wrap">
                                        Status :
                                    </p>
                                    <p v-if="item.deleted_at == null">
                                        Active
                                    </p>
                                    <p v-else>
                                        Inactive
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-no-wrap">
                                        Phone no :
                                    </p>
                                    <p class="text-right">
                                        {{ item.mobile }}
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-no-wrap">
                                        Email :
                                    </p>
                                    <p class="text-right">
                                        {{ item.email }}
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-no-wrap">
                                        Role :
                                    </p>
                                    <p class="text-right">
                                        {{ item.role_name.replaceAll('_', ' ') }}
                                    </p>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <p class="text-no-wrap">
                                        Assigned Date :
                                    </p>
                                    <p v-if="item.pic_created_at != null">
                                        {{ convertTimeZone(item.pic_created_at) }}
                                    </p>
                                </div>
                            </v-card>
                        </v-carousel-item>
                    </v-carousel>
                    <!-- <div class="d-flex justify-end mt-n4 mb-3">
                        <v-btn class="custom-button text-capitalize" outlined>
                            <span class="font-weight-bold">
                                Show All
                            </span>
                        </v-btn>
                    </div> -->
                </div>
                <div v-else>
                    <AWarning message="No Person-in-charge assigned" />
                </div>
            </v-tab-item>
        </v-tabs>

        <hr style="border: 2px solid #F2F3F7;" />
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex';

export default {
    components: {
        //
    },
    computed: mapState({
        //
    }),
    props: [
        //
    ],
    data: () => ({
        notAllowed: false,
        notAllowedAction: false,
        isLoadingClient: false,
        isLoadingSubscription: false,
        actionDrop: false,
        role: null,
        page: 1,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },
        allowedRoleUpdateStatus: ["developer", "vision_admin", "operation"],
        client: null,
        staff: null,
        subscription: null,
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class == "getCompanyPICAndStaff") {
                this.client = (resp.data.client.length != 0) ? resp.data.client : null;
                this.staff = (resp.data.staff.length != 0) ? resp.data.staff : null;
                this.isLoadingClient = false;
            }
            if (resp.class == "getLatestSubscription") {
                this.subscription = resp.data;
                let discount = this.$_.clone(this.subscription.total_discount);
                this.subscription.total_discount = (discount != null) ? String(discount) : null;
                this.isLoadingSubscription = false;
                return 1;
            }
        };
    },
    mounted() {
        this.fetch();
        // this.changeFlexShrink();
    },
    methods: {
        fetch() {
            let companyId = this.$router.history.current.params.id;
            let fetchClientPICApi = this.fetchClientPIC(companyId);
            this.$axios.all([
                this.$api.fetch(fetchClientPICApi),
            ]);
        },
        fetchClientPIC(companyId) {
            this.isLoadingClient = true;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/company/clientpic/" + companyId;
            return tempApi;
        },
        readAllSubscription() {
            this.$router.push({ name: 'CompaniesSubscription', params: { 'id': this.$router.history.current.params.id } });
        },
        convertTimeZone(time) {
            return this.$moment(time).format('LLL');
        },
        convertDateZone(time) {
            return this.$moment(time).format('LL');
        },
        checkIfCanAccessBonusOffer() {
            if (this.$store.getters.getFeatures.accounttype.status == 1 || (this.$store.getters.getFeatures.accounttype.beta == 1 && this.$store.getters.getRole.includes('developer'))) {
                return true;
            }
            return false;
        },
    },
}
</script>

<style scoped>
.custom-tabs .v-tab {
    border: solid #00688F;
    border-width: 2px 1px 2px 1px;
    margin-bottom: 10px;
}

.custom-active-tab {
    background-color: #00688F;
    color: white !important;
}

.custom-button {
    border: 2px solid black;
    border-radius: 5px;
}

/* .custom-pagination {
    margin-right: auto;
    margin-left: 300px;
} */
</style>