<template>
    <v-container>
        <AError :api="this.api" />
        <v-skeleton-loader type="card" v-if="this.isLoadingRenewal">
        </v-skeleton-loader>
            <v-row
        v-else>
            <v-col>
                <div class="text-h6 my-4 font-weight-bold">
                    Renewal Update Status
                </div>
                <div v-if="renewalStatus != null">
                    <div class="d-flex justify-start">
                        <p class="font-weight-bold">
                            {{ this.totalRenewalStatus }} renewal update 2023
                        </p>
                    </div>
                    <v-carousel height="auto" width="auto" :continuous="false" hide-delimiters>
                        <v-carousel-item v-for="(item, index) in renewalStatus" :key="index">
                            <div class="d-flex justify-space-between">
                                <p class="">
                                    Report by :
                                </p>
                                <p class="">
                                    {{ item.fname }} {{ item.lname }}
                                </p>
                            </div>
                            <div class="d-flex justify-space-between">
                                <p class="">
                                    Report date :
                                </p>
                                <p class="">
                                    {{ convertTimeZone(item.created_at) }}
                                </p>
                            </div>
                            <div class="d-flex justify-space-between">
                                <p class="">
                                    Renewal status :
                                </p>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip v-bind="attrs" v-on="on" class="white--text mt-n1"
                                            style="border-radius: 13px;" color="orange"
                                            v-if="item.renewal_status == 'Pending'">
                                            {{ item.renewal_status }}
                                        </v-chip>
                                        <v-chip v-bind="attrs" v-on="on" class="white--text mt-n1"
                                            style="border-radius: 13px;" color="green accent-3"
                                            v-if="item.renewal_status == 'Confirm'">
                                            {{ item.renewal_status }}
                                        </v-chip>
                                        <v-chip v-bind="attrs" v-on="on" class="white--text mt-n1"
                                            style="border-radius: 13px;" color="green accent-3"
                                            v-if="item.renewal_status == 'Confirm - Pending HRDC'">
                                            {{ item.renewal_status }}
                                        </v-chip>
                                        <v-chip v-bind="attrs" v-on="on" class="white--text mt-n1"
                                            style="border-radius: 13px;" color="grey"
                                            v-if="item.renewal_status == 'Unreachable'">
                                            {{ item.renewal_status }}
                                        </v-chip>
                                        <v-chip v-bind="attrs" v-on="on" class="white--text mt-n1"
                                            style="border-radius: 13px;" color="red"
                                            v-if="item.renewal_status == 'Rejected'">
                                            {{ item.renewal_status }}
                                        </v-chip>
                                    </template>
                                    <span>
                                        {{ item.status }}
                                    </span>
                                </v-tooltip>
                            </div>
                            <div class="d-flex justify-space-between" v-if="item.confirm_at != null">
                                <p class="">
                                    Client payment date :
                                </p>
                                <p>
                                    {{ convertTimeZone(item.confirm_at) }}
                                </p>
                            </div>
                            <div class="d-flex justify-space-between"
                                v-if="item.renewal_status == 'Pending' && item.next_followup_at != null">
                                <p class="">
                                    Next follow up date :
                                </p>
                                <p>
                                    {{ convertTimeZone(item.next_followup_at) }}
                                </p>
                            </div>
                            <div class="d-flex justify-space-between"
                                v-if="item.renewal_status == 'Rejected' && item.refund != null">
                                <p class="">
                                    Refund :
                                </p>
                                <p>
                                    {{ (item.refund == "1") ? "Yes" : "No" }}
                                </p>
                            </div>
                            <div class="">
                                <p class="">
                                    Remark :
                                </p>
                                <!-- <v-textarea class="rounded-lg" flat solo no-resize background-color="blue-grey lighten-5"
                                    readonly v-model="item.remark">
                                </v-textarea> -->
                                <mavon-editor previewBackground="#ECEFF1" :boxShadow="false"
                                    style="min-height: 100px; width: 100%; max-height: 100px; overflow-y: auto; border-radius: 0.5rem;"
                                    width="auto" defaultOpen="preview" :toolbarsFlag="false" :subfield="false" language="en"
                                    v-model="item.remark" :editable="false">
                                </mavon-editor>
                            </div>
                            <div class="d-flex justify-space-between my-3">
                                <div class="font-weight-bold mt-2">
                                    {{ index + 1 }} / {{ totalRenewalStatus }}
                                </div>
                                <v-btn class="custom-button text-capitalize" outlined @click="readAllRenewalUpdate">
                                    <span class="font-weight-bold">
                                        Show All
                                    </span>
                                </v-btn>
                            </div>
                        </v-carousel-item>
                    </v-carousel>
                </div>
                <div v-else>
                    <span>No renewal status update</span>
                </div>
            </v-col>
        </v-row>
        <hr style="border: 2px solid #F2F3F7;" />
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex';
import mavonEditor from 'mavon-editor';

export default {
    components: {
        'mavon-editor': mavonEditor.mavonEditor,
    },
    computed: mapState({
        //
    }),
    props: [
        //
    ],
    data: () => ({
        notAllowed: false,
        notAllowedAction: false,
        isLoadingRenewal: false,
        isLoadingPayment: false,
        actionDrop: false,
        role: null,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },
        allowedRoleUpdateStatus: ["developer", "vision_admin", "operation"],
        renewalStatus: [],
        totalRenewalStatus: null,
        latestPaymentForm: null,
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class == 'readFormWithLimit') {
                this.renewalStatus = (resp.data.form.data.length != 0) ? resp.data.form.data : null;
                this.totalRenewalStatus = resp.data.total;
                this.isLoadingRenewal = false;
            }
            if (resp.class == "readLatestForm") {
                this.isLoadingPayment = false;
                this.latestPaymentForm = resp.data;
            }
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            let companyId = this.$router.history.current.params.id;
            let fetchRenewalStatusApi = this.fetchRenewalStatus(companyId);
            this.$axios.all([
                this.$api.fetch(fetchRenewalStatusApi),
            ]);
        },
        fetchRenewalStatus(companyId) {
            this.isLoadingRenewal = true;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/form/page/6?companyId=" + companyId + "&formType=Renewal";
            return tempApi;
        },
        fetchPaymentStatus(companyId) {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/form/latest/" + companyId + "?formType=Payment";
            return tempApi;
        },
        readAllRenewalUpdate() {
            this.$router.push({ name: 'CompaniesRenewalUpdate', params: { 'id': this.$router.history.current.params.id } });
        },
        readAllPaymentUpdate() {
            this.$router.push({
                name: 'CompaniesPaymentUpdate', params: {
                    'id': this.$router.history.current.params.id
                }
            })
        },
        convertTimeZone(time) {
            return this.$moment(time).format('LLL');
        },
        removeNavigationButtons() {
            const prevButton = document.querySelector('.v-slide-group__prev');
            const nextButton = document.querySelector('.v-slide-group__next');

            if (prevButton && prevButton.parentNode) {
                prevButton.parentNode.removeChild(prevButton);
            }

            if (nextButton && nextButton.parentNode) {
                nextButton.parentNode.removeChild(nextButton);
            }
        },
        changeFlexShrink() {
            const slideGroupContent = document.querySelector('.v-slide-group__content');

            if (slideGroupContent) {
                slideGroupContent.style.flex = '1 1 auto';
            }
        },
    },
}
</script>

<style scoped>
.custom-tabs .v-tab {
    border: solid #00688F;
    border-width: 2px 1px 2px 1px;
    margin-bottom: 5px;
}

.custom-active-tab {
    background-color: #00688F;
    color: white !important;
}

.custom-button {
    border: 2px solid black;
    border-radius: 5px;
}

/* .custom-pagination {
    margin-right: auto;
    margin-left: 300px;
} */
</style>