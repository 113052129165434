<template>
    <v-container>
        <AError :api="this.api" />
        <v-skeleton-loader type="card" v-if="this.isLoadingPayment">
        </v-skeleton-loader>
        <v-tabs class="custom-tabs" active-class="custom-active-tab" slider-color="transparent" fixed-tabs
            v-if="!this.isLoadingPayment">
            <v-tab class="text-capitalize rounded-l black--text" style="border-width: 2px 2px 2px 1px;">
                latest payment <br /> status
            </v-tab>
            <v-tab
                class="text-capitalize  black--text"
                style="border-width:2px 2px 2px 1px;">
                Payment method
            </v-tab>
            <v-tab
                class="text-capitalize rounded-r black--text"
                style="border-width:2px 2px 2px 1px">
                Refund status
            </v-tab>
            <!-- <v-tab class="text-capitalize rounded-r black--text" style="border-width: 2px 2px 2px 1px;">
                receipt & invoices
            </v-tab> -->
            <v-tab-item>
                <div class="text-h6 my-4 font-weight-bold">
                    Latest Payment Status
                </div>
                <div v-if="latestPaymentForm != null">
                    <div class="d-flex justify-space-between">
                        <p class="">
                            Report by :
                        </p>
                        <p class="">
                            {{ latestPaymentForm.fname }} {{ latestPaymentForm.lname }}
                        </p>
                    </div>
                    <div class="d-flex justify-space-between">
                        <p class="">
                            Report date :
                        </p>
                        <p class="">
                            {{ convertTimeZone(latestPaymentForm.created_at) }}
                        </p>
                    </div>
                    <div class="d-flex justify-space-between" v-if="latestPaymentForm.total_amount != null">
                        <p class="">
                            Payment date :
                        </p>
                        <p class="">
                            {{ convertTimeZone(latestPaymentForm.payment_at) }}
                        </p>
                    </div>
                    <div class="d-flex justify-space-between" v-if="latestPaymentForm.total_amount != null">
                        <p class="">
                            Amount :
                        </p>
                        <p class="">
                            RM {{ latestPaymentForm.total_amount }}
                        </p>
                    </div>
                    <div class="d-flex justify-space-between" v-if="latestPaymentForm.payment_method != null">
                        <p class="">
                            Payment method :
                        </p>
                        <p class="">
                            {{ latestPaymentForm.payment_method }}
                        </p>
                    </div>
                    <div class="d-flex justify-space-between">
                        <p class="">
                            Status :
                        </p>
                        <div class="mt-n1">
                            <v-chip class="white--text" style="border-radius: 13px;" color="orange"
                                v-if="latestPaymentForm.status == 'Pending'">
                                {{ latestPaymentForm.status }}
                            </v-chip>
                            <v-chip class="white--text" style="border-radius: 13px;" color="green accent-3"
                                v-if="latestPaymentForm.status == 'Success'">
                                {{ latestPaymentForm.status }}
                            </v-chip>
                            <v-chip class="white--text" style="border-radius: 13px;" color="red"
                                v-if="latestPaymentForm.status == 'Fail'">
                                {{ latestPaymentForm.status }}
                            </v-chip>
                        </div>
                    </div>
                    <div class="">
                        <p class="">
                            Remark :
                        </p>
                        <v-textarea class="rounded-lg" flat solo no-resize background-color="blue-grey lighten-5" readonly
                            v-model="latestPaymentForm.remark">
                        </v-textarea>
                    </div>
                    <div class="d-flex justify-end mt-n4 mb-3">
                        <v-btn class="custom-button text-capitalize" outlined @click="readAllPaymentUpdate">
                            <span class="font-weight-bold">
                                Show All
                            </span>
                        </v-btn>
                    </div>
                </div>
                <div v-else>
                    <span>No payment status update</span>
                </div>
            </v-tab-item>
            <v-tab-item>
                <ComponentPrototypeCompanyPaymentMethod/>
            </v-tab-item>
            <v-tab-item>
                <ComponentPrototypeCompanyRefundStatus/>
            </v-tab-item>
            <!-- <v-tab-item>
                <div class="text-h6 my-4 font-weight-bold">
                    Receipt & Invoices
                </div>
                <div v-if="Object.keys(attachment).length != 0" class="mt-5">
                    <div v-for="(item, index) in this.attachment" :key="index">
                        <div v-if="index <= 2">
                            <div class="d-flex justify-space-between">
                                <div>
                                    <div class="d-flex flex-column">
                                        <div>
                                            <a :href="item.attachment_url" target="_blank" download
                                                class="text-decoration-none">
                                                {{ item.attachment_name }}

                                            </a>

                                        </div>
                                        <div>
                                            <span>
                                                {{ "#" + (index + 1) }} {{ item.attachment_type }}
                                            </span>

                                        </div>

                                    </div>
                                </div>
                                <div>
                                    {{ convertTimeZone(item.created_at) }}
                                </div>

                            </div>
                            <hr style="color:#F2F3F7" class=" my-2" />

                        </div>

                    </div>
                    <div class="d-flex justify-end pt-3">
                        <v-btn color="primary"
                            :to="{ name: 'PageAttachmentFormCompany', params: { 'id': this.$router.history.current.params.id } }">

                            Show All
                        </v-btn>
                    </div>
                </div>
                <div v-if="Object.keys(attachment).length == 0 && isLoadingAttachment == false">
                    <span class="d-flex justify-start text-h7">
                        No Receipt or Invoices recorded
                    </span>
                </div>
            </v-tab-item> -->
        </v-tabs>

        <hr style="border: 2px solid #F2F3F7;" />
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex';
import ComponentPrototypeCompanyPaymentMethod from './ComponentPrototypeCompanyPaymentMethod.vue';
import ComponentPrototypeCompanyRefundStatus from './ComponentPrototypeCompanyRefundStatus.vue';

export default {
    components: {
    ComponentPrototypeCompanyPaymentMethod,
    ComponentPrototypeCompanyRefundStatus
},
    computed: mapState({
        //
    }),
    props: [
        //
    ],
    data: () => ({
        notAllowed: false,
        notAllowedAction: false,
        isLoadingRenewal: false,
        isLoadingPayment: false,
        actionDrop: false,
        role: null,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },
        allowedRoleUpdateStatus: ["developer", "vision_admin", "operation"],
        renewalStatus: [],
        totalRenewalStatus: null,
        latestPaymentForm: null,
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
         
            if (resp.class == "readLatestForm") {
                this.latestPaymentForm = resp.data;
                this.isLoadingPayment = false;
            }
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            let companyId = this.$router.history.current.params.id;
            // let fetchSubscriptionApi = this.fetchCompanySubscription(companyId);
            let fetchLatestPaymentStatusApi = this.fetchPaymentStatus(companyId);
            // let fetchClientPICApi = this.fetchClientPIC(companyId);
            // let fetchRefundApi = this.fetchRefund();
            // let fetchAttachmentApi = this.fetchAttachment();
            // let fetchSpecialRemarkApi = this.fetchSpecialRemark();
            // let fetchCompanySubscriptionHistoryApi = this.fetchCompanySubscriptionHistory(companyId);
            // let fetchCompanyRefundApi = this.fetchCompanyRefund(companyId);
            this.isLoadingPayment = true;
            this.$axios.all([
                // this.$api.fetch(fetchSubscriptionApi),
                // this.$api.fetch(fetchClientPICApi),
                this.$api.fetch(fetchLatestPaymentStatusApi),
                // this.$api.fetch(fetchRefundApi),
                // this.$api.fetch(fetchAttachmentApi),
                // this.$api.fetch(fetchSpecialRemarkApi),
                // this.$api.fetch(fetchCompanySubscriptionHistoryApi),
                // this.$api.fetch(fetchCompanyRefundApi),
            ]);
        },
        fetchRenewalStatus(companyId) {
            this.isLoadingRenewal = true;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/form/page/6?companyId=" + companyId + "&formType=Renewal";
            return tempApi;
        },
        fetchPaymentStatus(companyId) {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/form/latest/" + companyId + "?formType=Payment";
            return tempApi;
        },
        readAllRenewalUpdate() {
            this.$router.push({ name: 'CompaniesRenewalUpdate', params: { 'id': this.$router.history.current.params.id } });
        },
        readAllPaymentUpdate() {
            this.$router.push({
                name: 'CompaniesPaymentUpdate', params: {
                    'id': this.$router.history.current.params.id
                }
            })
        },
        convertTimeZone(time) {
            return this.$moment(time).format('LLL');
        },
        removeNavigationButtons() {
            const prevButton = document.querySelector('.v-slide-group__prev');
            const nextButton = document.querySelector('.v-slide-group__next');

            if (prevButton && prevButton.parentNode) {
                prevButton.parentNode.removeChild(prevButton);
            }

            if (nextButton && nextButton.parentNode) {
                nextButton.parentNode.removeChild(nextButton);
            }
        },
        changeFlexShrink() {
            const slideGroupContent = document.querySelector('.v-slide-group__content');

            if (slideGroupContent) {
                slideGroupContent.style.flex = '1 1 auto';
            }
        },
    },
}
</script>

<style scoped>
.custom-tabs .v-tab {
    border: solid #00688F;
    border-width: 2px 1px 2px 1px;
    margin-bottom: 5px;
}

.custom-active-tab {
    background-color: #00688F;
    color: white !important;
}

.custom-button {
    border: 2px solid black;
    border-radius: 5px;
}

/* .custom-pagination {
    margin-right: auto;
    margin-left: 300px;
} */
</style>