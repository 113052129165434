<template>
    <v-container>
        <AConfirmation :isShow="isPendingVerification" @confirm="updateCompanyVerification"
            @cancel="cancelUpdateCompanyVerification" />
        <ASuccessWithoutReload :api="this.api" @close="() => { api.isSuccesful = false; }" />
        <AError :api="this.api" />
        <v-skeleton-loader type="card" v-if="this.isLoadingCompany">
        </v-skeleton-loader>
        <div v-if="!this.isLoadingCompany">
            <div class="text-h6 my-4 font-weight-bold">
                Company Details
            </div>
            <div class="d-flex justify-space-between">
                <p class="text-no-wrap">
                    Name :
                </p>
                <p class="text-right">
                    {{ company.name }}
                </p>
            </div>
            <div class="d-flex justify-space-between"
                v-if="this.allowedRoleUpdateStatus.some(role => this.$store.getters.getRole.includes(role))">
                <p class="text-no-wrap">
                    Stripe :
                </p>
                <p class="text-right">
                    {{ (company.company_stripe_id != null) ? company.company_stripe_id : null }}
                </p>
            </div>
            <div class="d-flex justify-space-between">
                <p class="text-no-wrap">
                    SSM :
                </p>
                <p class="text-right">
                    {{ (company.ssm_no) ? company.ssm_no : '-' }}
                </p>
            </div>
            <div class="d-flex justify-space-between">
                <p class="text-no-wrap">
                    Address :
                </p>
                <p class="text-right">
                    {{ (company.address) ? company.address : '-' }}
                </p>
            </div>
            <div class="d-flex justify-space-between">
                <p class="text-no-wrap">
                    Industry :
                </p>
                <p class="text-right">
                    {{ (company.industry) ? company.industry : '-' }}
                </p>
            </div>
            <div class="d-flex justify-space-between">
                <p class="text-no-wrap">
                    AAE :
                </p>
                <p class="text-right">
                    {{ (company.minutes != null) ? company.minutes + " Minutes" : '-' }}
                </p>
            </div>
            <div class="d-flex justify-space-between"
                v-if="this.allowedRoleUpdateStatus.some(role => this.$store.getters.getRole.includes(role))">
                <p class="text-no-wrap">
                    Status :
                </p>
            </div>
            <v-progress-circular v-if="isLoadingUpdateVerification" :size="50" color="primary"
                indeterminate></v-progress-circular>
            <v-select v-else @change="validateInputChangeVerification" dense outlined v-model="company.verification"
                :items="companyVerificationOption">
            </v-select>
        </div>

        <hr style="border: 2px solid #F2F3F7;" />
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex';
// import ANotAllowed from '../../components/common/ANotAllowed.vue';
// import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessWithoutReload from '../../common/ASuccessWithoutReload.vue';

export default {
    components: {
        // ANotAllowed,
        // AConfirmation,
        ASuccessWithoutReload,
    },
    computed: mapState({
        //
    }),
    props: [
        //
    ],
    data: () => ({
        isLoadingUpdateVerification: false,
        isPendingVerification: false,
        isLoadingCompany: false,
        actionDrop: false,
        role: null,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },
        allowedRoleUpdateStatus: ["developer", "vision_admin", "operation"],
        companyVerificationOption: ['valid', 'invalid', 'internal', 'unknown'],
        company: null,
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class == "getCompany") {
                this.company = resp.data;
                this.isLoadingCompany = false;
            }
            if (resp.class == 'updateCompanyValidity') {
                this.isLoadingUpdateVerification = false;
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Update Status";
            }
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            let companyId = this.$router.history.current.params.id;
            let fetchCompanyApi = this.fetchCompany(companyId);
            // let fetchSubscriptionApi = this.fetchCompanySubscription(companyId);
            // let fetchRenewalStatusApi = this.fetchRenewalStatus(companyId);
            // let fetchLatestPaymentStatusApi = this.fetchPaymentStatus(companyId);
            // let fetchRefundApi = this.fetchRefund();
            // let fetchAttachmentApi = this.fetchAttachment();
            // let fetchSpecialRemarkApi = this.fetchSpecialRemark();
            // let fetchCompanySubscriptionHistoryApi = this.fetchCompanySubscriptionHistory(companyId);
            // let fetchCompanyRefundApi = this.fetchCompanyRefund(companyId);
            this.$axios.all([
                this.$api.fetch(fetchCompanyApi),
                // this.$api.fetch(fetchSubscriptionApi),
                // this.$api.fetch(fetchRenewalStatusApi),
                // this.$api.fetch(fetchLatestPaymentStatusApi),
                // this.$api.fetch(fetchRefundApi),
                // this.$api.fetch(fetchAttachmentApi),
                // this.$api.fetch(fetchSpecialRemarkApi),
                // this.$api.fetch(fetchCompanySubscriptionHistoryApi),
                // this.$api.fetch(fetchCompanyRefundApi),
            ]);
        },
        fetchCompany(companyId) {
            this.isLoadingCompany = true;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/companies/" + companyId;
            return tempApi;
        },
        validateInputChangeVerification() {
            this.isPendingVerification = true;
        },
        updateCompanyVerification() {
            this.isPendingVerification = false;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API + '/company/verification/' + this.company.verification;
            tempApi.params = {
                'id': this.company.companies_id,
            };
            this.isLoadingUpdateVerification = true;
            this.$api.fetch(tempApi);
        },
        cancelUpdateCompanyVerification() {
            this.isPendingVerification = false;
            location.reload();
        },
    }
}
</script>