<template>
    <v-container>
        <AError :api="this.api" />
        <v-skeleton-loader type="card" v-if="this.isLoadingPayment">
        </v-skeleton-loader>
        <div v-if="!this.isLoadingPayment">
            <div class="text-h6 my-4 font-weight-bold">
                Payment Method
            </div>
            <div class="d-flex justify-space-between">
                <p class="font-weight-bold">
                    Credit Card
                </p>
            </div>
            <div class="d-flex justify-space-between">
                <p class="">
                    Brand :
                </p>
                <p class="">
                    {{ (company.card_brand) ? company.card_brand : '-' }}
                </p>
            </div>
            <div class="d-flex justify-space-between">
                <p class="">
                    Last 4 digits :
                </p>
                <p class="">
                    {{ (company.card_last_four) ? company.card_last_four : '-' }}
                </p>
            </div>
            <div class="d-flex justify-space-between">
                <p class="">
                    Expired at :
                </p>
                <p class="">
                    {{ (company.expired) ? company.expired : '-' }}
                </p>
            </div>
        </div>

    </v-container>
</template>
  
<script>
import { mapState } from 'vuex';
// import ANotAllowed from '../../components/common/ANotAllowed.vue';
// import AConfirmation from '../../components/common/AConfirmation.vue';
// import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';

export default {
    components: {
        // ANotAllowed,
        // AConfirmation,
        // ASuccessWithoutReload,
    },
    computed: mapState({
        //
    }),
    props: [
        //
    ],
    data: () => ({
        notAllowed: false,
        notAllowedAction: false,
        isLoadingPayment: false,
        actionDrop: false,
        role: null,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },
        allowedRoleUpdateStatus: ["developer", "vision_admin", "operation"],
        company: null,
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class == "getCompany") {
                this.company = resp.data;
                this.isLoadingPayment = false;
            }
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            let companyId = this.$router.history.current.params.id;
            let fetchCompanyApi = this.fetchCompany(companyId);
            // let fetchSubscriptionApi = this.fetchCompanySubscription(companyId);
            // let fetchRenewalStatusApi = this.fetchRenewalStatus(companyId);
            // let fetchLatestPaymentStatusApi = this.fetchPaymentStatus(companyId);
            // let fetchRefundApi = this.fetchRefund();
            // let fetchAttachmentApi = this.fetchAttachment();
            // let fetchSpecialRemarkApi = this.fetchSpecialRemark();
            // let fetchCompanySubscriptionHistoryApi = this.fetchCompanySubscriptionHistory(companyId);
            // let fetchCompanyRefundApi = this.fetchCompanyRefund(companyId);
            this.$axios.all([
                this.$api.fetch(fetchCompanyApi),
                // this.$api.fetch(fetchSubscriptionApi),
                // this.$api.fetch(fetchRenewalStatusApi),
                // this.$api.fetch(fetchLatestPaymentStatusApi),
                // this.$api.fetch(fetchRefundApi),
                // this.$api.fetch(fetchAttachmentApi),
                // this.$api.fetch(fetchSpecialRemarkApi),
                // this.$api.fetch(fetchCompanySubscriptionHistoryApi),
                // this.$api.fetch(fetchCompanyRefundApi),
            ]);
        },
        fetchCompany(companyId) {
            this.isLoadingPayment = true;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/companies/" + companyId;
            return tempApi;
        },
    }
}
</script>