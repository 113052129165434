<template>
    <v-container>
        <AError :api="this.api" />
        <v-skeleton-loader type="card" v-if="this.isLoadingAttachment">
        </v-skeleton-loader>
        <div v-if="!this.isLoadingAttachment">
            <div class="text-h6 my-4 font-weight-bold">
                Receipt & Invoices
            </div>
            <div v-if="Object.keys(attachment).length != 0">
                <div v-for="(item, index) in this.attachment" :key="index">
                    <div v-if="index <= 2">
                        <v-card class="pa-3 mb-2" flat color="blue-grey lighten-5">
                            <div>
                                <span>
                                    {{ "#" + (index + 1) }} {{ item.attachment_type }}
                                </span>
                            </div>
                            <v-row>
                                <v-col>
                                    <a :href="item.attachment_url" target="_blank" download class="text-decoration-none">
                                        {{ item.attachment_name }}
                                    </a>
                                </v-col>
                                <v-col>
                                    <div class=" text-right">
                                        {{ convertTimeZone(item.created_at) }}
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </div>
                </div>
                <div class="d-flex justify-end mt-3 mb-3">
                    <v-btn class="custom-button text-capitalize" outlined
                        :to="{ name: 'PageAttachmentFormCompany', params: { 'id': this.$router.history.current.params.id } }">
                        <span class="font-weight-bold">
                            Show All
                        </span>
                    </v-btn>
                </div>
            </div>
            <div v-if="Object.keys(attachment).length == 0 && isLoadingAttachment == false">
                <span class="d-flex justify-start text-h7">
                    No Receipt or Invoices recorded
                </span>
            </div>
        </div>

        <!-- <hr style="border: 2px solid #F2F3F7;" /> -->
    </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
    components: {
        //
    },
    computed: mapState({
        //
    }),
    props: [
        //
    ],
    data: () => ({
        attachment: null,
        notAllowed: false,
        notAllowedAction: false,
        isLoadingAttachment: false,
        actionDrop: false,
        role: null,
        page: 1,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },
        allowedRoleUpdateStatus: ["developer", "vision_admin", "operation"],
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class == "getAttachmentCompany") {
                this.attachment = resp.data;
                this.isLoadingAttachment = false;
            }
        };
    },
    mounted() {
        this.fetch();
        this.removeNavigationButtons();
        this.changeFlexShrink();
    },
    methods: {
        fetch() {
            let fetchAttachmentApi = this.fetchAttachment();
            this.$axios.all([
                this.$api.fetch(fetchAttachmentApi),
            ]);
        },
        fetchAttachment() {
            this.isLoadingAttachment = true;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + '/attachment/company/' + this.$router.history.current.params.id+"?types[]=Receipt&types[]=Invoice";
            return tempApi;
        },
        convertTimeZone(time) {
            return this.$moment(time).format('LLL');
        },
        removeNavigationButtons() {
            const prevButton = document.querySelector('.v-slide-group__prev');
            const nextButton = document.querySelector('.v-slide-group__next');

            if (prevButton && prevButton.parentNode) {
                prevButton.parentNode.removeChild(prevButton);
            }

            if (nextButton && nextButton.parentNode) {
                nextButton.parentNode.removeChild(nextButton);
            }
        },
        changeFlexShrink() {
            const slideGroupContent = document.querySelector('.v-slide-group__content');

            if (slideGroupContent) {
                slideGroupContent.style.flex = '1 1 auto';
            }
        },
    },
}
</script>

<style scoped>
.custom-button {
    border: 2px solid black;
    border-radius: 5px;
}
</style>