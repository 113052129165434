<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-row
            v-else>
            <v-col>
                <div
                    class="text-h6 my-4 font-weight-bold">
                    Refund attachment
                </div>
                <div
                    v-if="Object.keys(attachment).length != 0"> 
                    <div
                        v-for="(item, index) in this.attachment"
                        :key="index">
                        <div
                            v-if="index <=2">
                            <v-card
                                class="pa-3 mb-2" flat color="blue-grey lighten-5">
                                <span>
                                    {{ "#" + (index + 1) }} {{ item.attachment_type }}
                                </span>
                                <v-row
                                    class="mt-3">
                                    <v-col>
                                        <a
                                            :href="item.attachment_url" 
                                            target="_blank"
                                            download
                                            class="text-decoration-none">
                                            {{  item.attachment_name }}
                                        </a>
                                    </v-col>
                                    <v-col>
                                        <div
                                            class="d-flex justify-end">
                                            <div
                                                class="text-right">
                                                {{ convertTimeZone(item.created_at) }}
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        class="mt-3">
                                        <div
                                            class="d-flex justify-start">
                                            Remark :
                                        </div>
                                        <mavon-editor previewBackground="#ECEFF1" :boxShadow="true"
                                        style="min-height: 100px; width: 100%; max-height: 100px; overflow-y: auto; border-radius: 0.5rem;"
                                        width="auto" defaultOpen="preview" :toolbarsFlag="false" :subfield="false" language="en"
                                        v-model="item.remark" :editable="false">
                                    </mavon-editor>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </div>
                    </div>
                    <div
                        class="d-flex justify-end mt-3 mb-3">
                        <v-btn class="custom-button text-capitalize" outlined
                        :to="{ name: 'PageAttachmentFormCompany', params: { 'id': this.$router.history.current.params.id } }">
                            <span class="font-weight-bold">
                                Show All
                            </span>
                        </v-btn>
                    </div>
                </div>
                <div
                    v-if="Object.keys(attachment).length == 0 && api.isLoading == false">
                    <span
                        class="d-flex justify-start text-h7">
                        No Refund attachment recorded
                    </span>
                </div>
            </v-col>
            <hr style="border: 2px solid #F2F3F7;" />
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import mavonEditor from 'mavon-editor';
// import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';
export default {
    components:{
    //         WithoutReload
    'mavon-editor':mavonEditor.mavonEditor,
},
    computed: mapState({
    //
}),
    props:[
    //
    ],
    data: () => ({
        attachment: null,
        notAllowed: false,
        notAllowedAction: false,
        isLoadingAttachment: false,
        actionDrop: false,
        role: null,
        page: 1,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },
        allowedRoleUpdateStatus: ["developer", "vision_admin", "operation"],

    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class ==='getAttachmentCompany') {
                this.attachment = resp.data.map((element)=>{
                    if(element.remark==null || element.remark=='null' || element.remark=='') {
                        element.remark = "No remark recorded";
                    }
                    return element;
                });
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.$api.fetch(this.fetchRefundAttachment());
        },
        fetchRefundAttachment() {
            let tempApi =  this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/attachment/company/' + this.$router.history.current.params.id+"?types[]=Refund";
            return tempApi;
        },
        // BOC : Create a new function to build the api and return it to the fetch function

        // EOC
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
        },
        convertTimeZone(time) {
            return this.$moment(time).format('LLL');
        },
    }
}
</script>