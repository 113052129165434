<template>
    <v-container>
        <AError :api="this.api" />
        <v-skeleton-loader type="card" v-if="this.isLoadingClient">
        </v-skeleton-loader>
        <v-tabs class="custom-tabs" active-class="custom-active-tab" slider-color="transparent" fixed-tabs
            v-if="!this.isLoadingClient">
            <v-tab class="text-capitalize rounded-l black--text" style="border-width: 2px 1px 2px 2px;">
                Invoice
                <!-- Title 1 -->
            </v-tab>
            <v-tab class="text-capitalize black--text">
                HRDC
                <!-- Title 2 -->
            </v-tab>
            <v-tab-item>
                <ComponentPrototypeCompanyClientInvoiceRecords
                    :companyId="this.companyId"/>
                <!-- Item 1-->
            </v-tab-item>
            <v-tab-item>
                <ComponentPrototypeCompanyHRDC
                    :companyId="this.companyId"/>
                <!-- Item 2 -->
            </v-tab-item>
        </v-tabs>

        <hr style="border: 2px solid #F2F3F7;" />
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex';
import ComponentPrototypeCompanyClientInvoiceRecords from '../../developer/prototype/ComponentPrototypeCompanyClientInvoiceRecords.vue';
import ComponentPrototypeCompanyHRDC from '../../developer/prototype/ComponentPrototypeCompanyHRDC.vue';

export default {
    components: {
    ComponentPrototypeCompanyClientInvoiceRecords,
    ComponentPrototypeCompanyHRDC
},
    computed: mapState({
        //
    }),
    props: [
        'companyId'
    ],
    data: () => ({
        notAllowed: false,
        notAllowedAction: false,
        isLoadingClient: false,
        isLoadingSubscription: false,
        actionDrop: false,
        role: null,
        page: 1,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },
        allowedRoleUpdateStatus: ["developer", "vision_admin", "operation"],
        client: null,
        staff: null,
        subscription: null,
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class == "getCompanyPICAndStaff") {
                this.client = (resp.data.client.length != 0) ? resp.data.client : null;
                this.staff = (resp.data.staff.length != 0) ? resp.data.staff : null;
                this.isLoadingClient = false;
            }
            if (resp.class == "getLatestSubscription") {
                this.subscription = resp.data;
                let discount = this.$_.clone(this.subscription.total_discount);
                this.subscription.total_discount = (discount != null) ? String(discount) : null;
                this.isLoadingSubscription = false;
                return 1;
            }
        };
    },
    mounted() {
        this.fetch();
        // this.changeFlexShrink();
    },
    methods: {
        fetch() {
            let fetchClientPICApi = this.fetchClientPIC();
            this.$axios.all([
                this.$api.fetch(fetchClientPICApi),
            ]);
        },
        fetchClientPIC() {
            this.isLoadingClient = true;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/company/clientpic/" + this.companyId;
            return tempApi;
        },
        readAllSubscription() {
            this.$router.push({ name: 'CompaniesSubscription', params: { 'id': this.companyId } });
        },
        convertTimeZone(time) {
            return this.$moment(time).format('LLL');
        },
        checkIfCanAccessBonusOffer() {
            if (this.$store.getters.getFeatures.accounttype.status == 1 || (this.$store.getters.getFeatures.accounttype.beta == 1 && this.$store.getters.getRole.includes('developer'))) {
                return true;
            }
            return false;
        },
    },
}
</script>

<style scoped>
.custom-tabs .v-tab {
    border: solid #00688F;
    border-width: 2px 1px 2px 1px;
    margin-bottom: 10px;
}

.custom-active-tab {
    background-color: #00688F;
    color: white !important;
}

.custom-button {
    border: 2px solid black;
    border-radius: 5px;
}

/* .custom-pagination {
    margin-right: auto;
    margin-left: 300px;
} */
</style>