<template>
    <v-container>
        <AWarning :message="warningNoSubscription" v-if="this.subscription == null && this.isLoadingSubscription == false">
        </AWarning>
        <AError :api="this.api" />
        <v-skeleton-loader type="card" v-if="this.isLoadingSubscription">
        </v-skeleton-loader>
        <v-row
            v-else>
            <v-col>
                <div class="text-h6 my-4 font-weight-bold">
                    Last Subscription From Stripe
                </div>
                <div v-if="this.subscription != null">
                    <v-card class="px-5 pt-5 mb-7 rounded-lg" flat color="blue-grey lighten-5">
                        <div class="d-flex justify-space-between">
                            <p class="text-no-wrap">
                                Name :
                            </p>
                            <p class="text-right">
                                {{ subscription.plan_name }}
                            </p>
                        </div>
                        <v-row>
                            <v-col
                                class="pb-0">
                                Status
                            </v-col>
                            <v-col
                                class="text-right">
                                <p
                                    class="font-weight-bold">
                                    {{ subscription.stripe_status }}            
                                </p>
                            </v-col>
                        </v-row>
                       
                        <div class="d-flex justify-space-between">
                            <p class="text-no-wrap">
                                Price :
                            </p>
                            <p class="text-decoration-line-through" v-if="(subscription.total_discount != null)">
                                RM {{ subscription.price }}
                            </p>
                            <p v-else>
                                RM {{ subscription.price }}
                            </p>
                        </div>
                       
                        <!-- <div class="d-flex justify-space-between">
                            <p class="text-no-wrap">
                                Status :
                            </p>
                            {{ subscription.refund_status }}
                            <p class="font-weight-bold" v-if="subscription.refund_status == 'Canceled'">
                                Cancelled
                            </p>
                            <p class="font-weight-bold" v-else>
                                {{ (subscription.invoice_status == "void") ? "Cancelled" : subscription.invoice_status }}
                            </p>
                        </div> -->
                        <!-- <div
                            class="d-flex justify-space-between">
                            <p
                                class="text-no-wrap">  
                                Discount code :
                            </p>
                            <div
                                class="">
                                <p
                                    class="text-right"
                                    v-if="subscription.invoice_coupon!=null">
                                    {{ (subscription.invoice_coupon)}}
                                </p>
                                <p
                                    class="text-right"
                                    v-else>
                                    -
                                </p>
                            </div>
                        </div> -->
                        <div class="d-flex justify-space-between" v-if="subscription.total_discount != null">
                            <p class="text-no-wrap">
                                Discount :
                            </p>
                            <p class="text-right">
                                RM {{ (subscription.total_discount != 0) ? subscription.total_discount.slice(0, -2) : 0 }}
                            </p>
                        </div>
                        <div class="d-flex justify-space-between" v-if="subscription.total_discount != null">
                            <p class="text-no-wrap">
                                Total price :
                            </p>
                            <p
                                class="text-right"
                                v-if="subscription.invoice_coupon!='null'">

                            </p>
                            <!-- <p class="text-right">
                                {{ (subscription.amount_paid != 0) ? "RM " + subscription.amount_paid.slice(0, -2) :
                                    "Non-Stripe Payment" }}
                            </p> -->
                            <p
                                class="text-right"
                                v-if="subscription.amount_paid != 0">
                                <!-- if user did not -->
                                "RM "{{ subscription.amount_paid.slice(0,-2) }}
                            </p>
                            <div
                                v-if="subscription.amount_paid == 0">
                                <div
                                    v-if="subscription.invoice_coupon!=null">
                                    <p
                                        v-if="subscription.invoice_coupon=='Qn0HzJYH'">
                                        <!-- If user apply for manual payment -->
                                        {{ "Non-Stripe Payment" }}
                                    </p>
                                    <p
                                        v-else>
                                    {{ subscription.invoice_coupon }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-space-between">
                            <p class="text-no-wrap">
                                Referral code :
                            </p>
                            <p class="text-right">
                                {{ (subscription.coupon && subscription.coupon!=='null') ? subscription.coupon : "Not applicable" }}
                            </p>
                        </div>
                     
                        <div class="d-flex justify-space-between">
                            <p class="text-no-wrap">
                                Subscription date :
                            </p>
                            <p class="text-right">
                                {{ convertTimeZone(subscription.period_start_date) }}
                            </p>
                        </div>
                        <div class="d-flex justify-space-between">
                            <p class="text-no-wrap">
                                Next renewal date :
                            </p>
                            <p class="text-right">
                                {{ (subscription.stripe_status.toLowerCase() !== 'canceled' && subscription.stripe_status.toLowerCase() !=='incomplete')?convertTimeZone(subscription.period_end_date):'-' }}
                            </p>
                        </div>
                    </v-card>
                    <div class="d-flex justify-end mt-n4 mb-3">
                        <v-btn class="custom-button text-capitalize" outlined @click="readAllSubscription">
                            <span class="font-weight-bold">
                                Show All
                            </span>
                        </v-btn>
                    </div>
                </div>
                <div v-else>
                    <span>No subscription found</span>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex';

export default {
    components: {
        //
    },
    computed: mapState({
        //
    }),
    props: [
        //
    ],
    data: () => ({
        notAllowed: false,
        notAllowedAction: false,
        isLoadingClient: false,
        isLoadingSubscription: false,
        actionDrop: false,
        role: null,
        page: 1,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },
        allowedRoleUpdateStatus: ["developer", "vision_admin", "operation"],
        client: null,
        staff: null,
        subscription: null,
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            // if (resp.class == "getCompanyPICAndStaff") {
            //     this.client = (resp.data.client.length != 0) ? resp.data.client : null;
            //     this.staff = (resp.data.staff.length != 0) ? resp.data.staff : null;
            //     this.isLoadingClient = false;
            // }
            if (resp.class == "getLatestSubscription") {
                this.subscription = resp.data;
                let discount = this.$_.clone(this.subscription.total_discount);
                this.subscription.total_discount = (discount != null) ? String(discount) : null;
                if(this.subscription.stripe_status!=null) {
                    let tempStripeStatus = this.$_.clone(this.subscription.stripe_status);
                    this.subscription.refund_status = tempStripeStatus.charAt(0).toUpperCase()+ tempStripeStatus.slice(1);
                }
                this.isLoadingSubscription = false;
                return 1;
            }
        };
    },
    mounted() {
        this.fetch();
        // this.changeFlexShrink();
    },
    methods: {
        fetch() {
            let companyId = this.$router.history.current.params.id;
            let fetchSubscriptionApi = this.fetchCompanySubscription(companyId);
        (companyId);
            this.$axios.all([
                this.$api.fetch(fetchSubscriptionApi),  
            ]);
        },
        fetchClientPIC(companyId) {
            this.isLoadingClient = true;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/company/clientpic/" + companyId;
            return tempApi;
        },
        // fetchSchedulerSubcription() {
            
        // },
        fetchCompanySubscription(companyId) {
            this.isLoadingSubscription = true;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/subscription/" + companyId + "/latest";
            return tempApi;
        },
        readAllSubscription() {
            this.$router.push({ name: 'CompaniesSubscription', params: { 'id': this.$router.history.current.params.id } });
        },
        convertTimeZone(time) {
            return this.$moment(time).format('LLL');
        },
        checkIfCanAccessBonusOffer() {
            if (this.$store.getters.getFeatures.accounttype.status == 1 || (this.$store.getters.getFeatures.accounttype.beta == 1 && this.$store.getters.getRole.includes('developer'))) {
                return true;
            }
            return false;
        },
    },
}
</script>

<style scoped>
.custom-tabs .v-tab {
    border: solid #00688F;
    border-width: 2px 1px 2px 1px;
    margin-bottom: 10px;
}

.custom-active-tab {
    background-color: #00688F;
    color: white !important;
}

.custom-button {
    border: 2px solid black;
    border-radius: 5px;
}

/* .custom-pagination {
    margin-right: auto;
    margin-left: 300px;
} */
</style>